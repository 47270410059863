
<template>
  <div class="Teacher-Course">
    <div class="stu-module-header">
      <div class="stu-module-title">我的课程</div>
    </div>
    <div v-if="queryRoot">
      <el-form inline size="medium">
        <el-form-item label="课程名称/编号">
          <el-input
            v-model="searchText"
            placeholder="课程名称/课程编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" :height="height" v-loading="loading">
        <!-- <el-table-column label="序号" type="index" width="60" align="center"> -->
        <!-- </el-table-column> -->
        <el-table-column prop="coverUrl" min-width="160" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.coverUrl" class="coverUrl">
              <div slot="error" class="image-slot">
                <img class="image-none" src="../../../assets/logo_top.png" />
                <!-- <i class="el-icon-picture-outline"></i> -->
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
		data-label="课程名称"
          label="课程名称"
          prop="courseName"
          min-width="120"
          align="center"
		  
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleCourseName(scope.row)">{{
              scope.row.courseName
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="课程编号"
          prop="courseCode"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学生人数"
          prop="studentNum"
          min-width="120"
          align="center"
        ></el-table-column>

        <el-table-column
          label="发帖数"
          prop="postNum"
          align="center"
        ></el-table-column
        ><el-table-column
          label="回帖数"
          prop="replyNum"
          align="center"
        ></el-table-column>
        <el-table-column
          label="在线作业"
          prop="workNum"
          align="center"
        ></el-table-column>
        <el-table-column
          label="在线考试"
          prop="examNum"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="100" v-if="btnRoot">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleCourse(scope.row)">
              点击进入
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <no-root v-else />
  </div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
import { TeacherCourseList } from "@/libs/api/teacher/study";
export default {
  name: "teacher-notice",
  data() {
    return {
      loading: false,
      height: window.innerHeight - 300,
      page: 1,
      pageSize: 10,
      searchText: "",
      tableData: [],
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherCourse:list");
    },
    btnRoot() {
      return this.$sysPerKey("eduTeacherCourse:join");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.query();
      }
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    query() {
      this.loading = true;
      const p = {
        page: this.page,
        pageSize: this.pageSize,
        info: this.searchText,
      };
      TeacherCourseList(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCourse(item) {
      if (!item.courseType && item.courseType !== 0)
        return this.$message.error("服务端未返回课程类型字段");
      this.$store.dispatch("set_t_course", item);
      this.openCourse(item.courseType);
    },
    handleCourseName(item) {
      if (!item.courseType && item.courseType !== 0)
        return this.$message.error("服务端未返回课程类型字段");
      this.$store.dispatch("set_t_course", item);
      this.openCourse(item.courseType);
    },
    openCourse(courseType) {
      courseType = courseType + "";
      // localStorage.setItem("courseId", item.id);
      // 普通课程
      if (courseType === COURSE_TYPE.T0) {
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T0);
        this.$router.push("/teacher/t/course/resources");
      }
      // 毕业论文
      if (courseType === COURSE_TYPE.T1) {
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T1);
        this.$router.push("/teacher/t/course/manage");
      }
      // 毕业大作业
      if (courseType === COURSE_TYPE.T2) {
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T2);
        this.$router.push("/teacher/t/course/manage");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Teacher-Course {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 20px;
  }
  .coverUrl {
    width: 90px;
    height: 90px;
    background: #f5f7fa;
    border-radius: 4px;
  }
  .image-none {
    border-radius: 4px;
    width: 80px;
    height: 50px;
    margin: 20px auto;
    display: block;
  }
} 
</style>
